import { useState, useEffect } from 'react';
import { useLoaderData, useNavigate, useRouteLoaderData, Link } from 'react-router-dom';
import { decodeToken } from '../../util/auth';
import moment from 'moment-timezone';

import MapComponent from './MapComponent';
import FightCard from './FightCard';
import FighterCard from './FighterCard';
import TripleBannerAds from '../ui/TripleBannerAds';
import Footer from "../ui/Footer";

import styles from './EventContent.module.css';

const EventContent = () => {
  const token = useRouteLoaderData('root');
  const navigate = useNavigate();
  const event = useLoaderData();
  const location = {
    id: event.id,
    coordinates: event.coordinates,
    type: 'EVENT',
  }

  const [activeTab, setActiveTab] = useState("matchedFights");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickPromotion = () => {
    navigate('/promotion/' + event.promotion_id);
  }

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const convertToTimezone = (utcDateTime) => {
    const convertedTime = moment.utc(utcDateTime).tz('Europe/London');
    return {
      date: convertedTime.format('DD/MM/yyyy'),
      time: convertedTime.format('HH:mm')
    };
  }

  const joinStrings = (arr) => {
    return arr.map(item => item.replace(/_/g, ' ')).join(', ');
  }

  const isFutureEvent = (dateString) => {
    const eventDate = new Date(dateString);
    eventDate.setHours(0, 0, 0, 0); // ignore time

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // ignore time

    return eventDate > currentDate;
  }

  const profilePictureSrc = event.promotion.image_url ? event.promotion.image_url : process.env.PUBLIC_URL + '/authlogo.png';
  const eventPictureSrc = event.image_url ? event.image_url : profilePictureSrc;
  const eventStart = convertToTimezone(event.date_time);
  const weighIn = convertToTimezone(event.weigh_in);

  return (
    <>
      <TripleBannerAds type="top" />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.leftSection}>
            <div className={styles.logoSection}>
              <img className={styles.eventPicture} src={`${eventPictureSrc}?timestamp=${new Date().getTime()}`} alt="EventPicture" />
            </div>
            <div className={styles.titleSection}>
              <h1>{event.name}</h1>
              <h3 className={styles.promotionName} onClick={handleClickPromotion}>{event.promotion.organisation}</h3>
              <ul className={styles.info}>
                <li className={styles.location}>{event.address}</li>
                <li className={styles.dateTime}>Event Start: {eventStart.time} on {eventStart.date}</li>
                <li className={styles.dateTime}>
                  Weigh In: {weighIn.time} on {weighIn.date} - {event.weigh_in_type === 'IN_PERSON' ? 'In Person' : 'Video'}
                </li>
                <li className={styles.sports}>Sports: {joinStrings(event.sports)}</li>
                {event.tapology_url &&
                  <a href={event.tapology_url.startsWith('http://') || event.tapology_url.startsWith('https://') ? event.tapology_url : `https://${event.tapology_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Tapology
                  </a>}
              </ul>
              {event.showInbox && (
                <div className={styles.actionButtons}>
                  {decodedToken && decodedToken.activeRoleUserId === event.promotion_id && isFutureEvent(event.date_time) &&
                    <>
                      <Link to={'/dashboard/edit-event/' + event.id}>
                        Edit Event
                      </Link>
                      <Link to={'delete'}>
                        Delete Event
                      </Link>
                    </>
                  }
                  <Link to={`/inbox?selectedInbox=${event.inbox_id}`}>
                    Go to Inbox
                  </Link>
                </div>
              )}
              <hr />
              <h2>{event.fights.length} MATCHED FIGHT{event.fights.length !== 1 ? 'S' : ''}</h2>
            </div>
          </div>
          <div className={styles.rightSection}>
            <MapComponent locations={[location]} height="250px" width="250px" controls={false} />
          </div>
        </div>
        {isFutureEvent(event.date_time) && (
          <>
            <div className={styles.tabsContainer}>
              <button
                className={`${styles.tab} ${activeTab === "matchedFights" ? styles.activeTab : ""}`}
                onClick={() => setActiveTab("matchedFights")}
              >
                Matched Fights
              </button>
              <button
                className={`${styles.tab} ${activeTab === "availableFighters" ? styles.activeTab : ""}`}
                onClick={() => setActiveTab("availableFighters")}
              >
                Available Fighters
              </button>
            </div>
            {process.env.REACT_APP_SITE_PENDING !== 'true' && decodedToken && decodedToken.authorities[0] === 'ROLE_GYM' &&
              <Link className={styles.submitLink} to="submit-fighter">
                Submit Available Fighter
              </Link>
            }
            {process.env.REACT_APP_SITE_PENDING !== 'true' && decodedToken && decodedToken.activeRoleUserId === event.promotion_id &&
              <Link className={styles.submitLink} to="fight-matcher">
                Match Fighters
              </Link>
            }
            {process.env.REACT_APP_SITE_PENDING === 'true' &&
              <h1 className={styles.pending}>Fight Matching Coming Soon</h1>
            }
          </>
        )}
        {activeTab === "matchedFights" && (
          <ul className={styles.fightsList}>
            {event.fights.map(fight => (
              <li key={fight.id} className={styles.fightsListItem}>
                <FightCard fight={fight} eventId={event.id} />
              </li>
            ))}
          </ul>
        )}
        {activeTab === "availableFighters" && (
          <ul className={styles.availableFightersList}>
            {event.available_fighters.map((fighter, index) => (
              <li key={index} className={styles.availableFightersListItem}>
                <FighterCard fighter={fighter} gym={decodedToken?.activeRoleUserId} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <Footer />
    </>
  );
}

export default EventContent;