import { useEffect } from 'react';
import { useLoaderData, useRouteLoaderData, Link, useNavigate } from 'react-router-dom';
import { decodeToken, tokenLoader } from '../../util/auth';

import MapComponent from './MapComponent';
import FighterCard from './FighterCard';
import TripleBannerAds from '../ui/TripleBannerAds';
import Footer from "../ui/Footer";

import styles from './GymContent.module.css'

const GymContent = () => {
  const token = useRouteLoaderData('root');
  const gym = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = {
    id: gym.user_id,
    coordinates: gym.coordinates,
    type: 'GYM',
  }

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const activeFighters = gym.fighters.filter(fighter => fighter.active);
  const inactiveFighters = gym.fighters.filter(fighter => !fighter.active);

  const profilePictureSrc = gym.image_url ? gym.image_url : process.env.PUBLIC_URL + '/authlogo.png';

  const handleMessageGym = async () => {
    const latestToken = await tokenLoader();
    const authUrl = process.env.REACT_APP_AUTH_URL;
    try {
      const response = await fetch(`${authUrl}/api/v1/inbox/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${latestToken}`
        },
        body: JSON.stringify({ participants: [decodedToken.activeRoleUserId, gym.user_id] })
      });

      if (!response.ok) {
        throw new Error('Failed to create chat');
      }

      const data = await response.json();
      const inboxId = data.inbox_created;

      navigate(`/inbox?selectedInbox=${inboxId}`);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  return (
    <>
      <TripleBannerAds type="top" />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.leftSection}>
            <div className={styles.logoSection}>
              <img className={styles.profilePicture} src={`${profilePictureSrc}?timestamp=${new Date().getTime()}`} alt="ProfilePicture" />
            </div>
            <div className={styles.titleSection}>
              <h1>{gym.organisation}</h1>
              <ul className={styles.info}>
                <li className={styles.location}>{gym.address}</li>
                <li>Contact Email: {gym.contact_email}</li>
                <br />
                <div className={styles.infoLinks}>
                  {gym.instagram &&
                    <a href={gym.instagram.startsWith('http://') || gym.instagram.startsWith('https://') ? gym.instagram : `https://${gym.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={process.env.PUBLIC_URL + '/instagram-icon.png'} alt="Logo" />
                    </a>}
                  {gym.facebook &&
                    <a href={gym.facebook.startsWith('http://') || gym.facebook.startsWith('https://') ? gym.facebook : `https://${gym.facebook}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={process.env.PUBLIC_URL + '/facebook-icon.png'} alt="Logo" />
                    </a>}
                  {gym.x_platform &&
                    <a href={gym.x_platform.startsWith('http://') || gym.x_platform.startsWith('https://') ? gym.x_platform : `https://${gym.x_platform}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={process.env.PUBLIC_URL + '/x-icon.png'} alt="Logo" />
                    </a>}
                  {gym.youtube &&
                    <a href={gym.youtube.startsWith('http://') || gym.youtube.startsWith('https://') ? gym.youtube : `https://${gym.youtube}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={process.env.PUBLIC_URL + '/youtube-icon.png'} alt="Logo" />
                    </a>}
                </div>
                {gym.tapology_url &&
                  <a href={gym.tapology_url.startsWith('http://') || gym.tapology_url.startsWith('https://') ? gym.tapology_url : `https://${gym.tapology_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Tapology
                  </a>}
              </ul>
              {decodedToken && decodedToken.activeRoleUserId === gym.user_id &&
                <Link className={styles.addFighterBtn} to="/dashboard/add-fighter">
                  Add Fighter
                </Link>
              }
              {decodedToken && decodedToken.authorities[0] === 'ROLE_ADMIN' &&
                <Link className={styles.addFighterBtn} to="add-fighter">
                  Add Fighter
                </Link>
              }
              {decodedToken && decodedToken.activeRoleUserId !== gym.user_id && (
                <button className={styles.messageBtn} onClick={handleMessageGym}>
                  Send Message
                </button>
              )}
              <hr />
              <div className={styles.bottomSection}>
                <h2>{activeFighters.length} ACTIVE FIGHTER{activeFighters.length !== 1 ? 'S' : ''}</h2>
              </div>
            </div>
          </div>
          <div className={styles.rightSection}>
            <MapComponent locations={[location]} height="250px" width="250px" controls={false} />
          </div>
        </div>
        <ul className={styles.itemsList}>
          {activeFighters.map(fighter => (
            <li key={fighter.id} className={styles.itemsListItem}>
              <FighterCard fighter={fighter} />
            </li>
          ))}
        </ul>
        {inactiveFighters.length > 0 && <h2>Inactive Fighters</h2>}
        <ul className={styles.itemsList}>
          {inactiveFighters.map(fighter => (
            <li key={fighter.id} className={styles.itemsListItem}>
              <FighterCard fighter={fighter} />
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default GymContent;